import schttp from 'public/src/services/schttp/index'
import { checkFirstPopup } from './utils'

export function getCouponPackageDataApi(params) {
  return schttp({
    url: '/promotion/coupon/combine_coupon',
    method: 'GET',
    useBffApi: true,
    params: {
      firstPopup: checkFirstPopup(),
      version: 'v2',
      blackBox: window?._fmOpt?.__blackbox || '',
      ...params,
    },
  })
}

export function bindCouponPackageApi(params) {
  return schttp({
    url: '/promotion/coupon/bind_coupon',
    method: 'POST',
    useBffApi: true,
    data: params,
  })
}

export function getRemindCouponPackageDataApi(params) {
  return schttp({
    url: '/promotion/member_coupon_list',
    method: 'GET',
    useBffApi: true,
    params,
  })
}

export function getLanguageByKeysApi(keys) {
  return schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys: keys,
    },
    useBffApi: true,
  })
}


export function getAbtDataApi(posKeys) {
  return schttp({
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys,
    },
    useBffApi: true,
  })
}


export function bindExpandCouponPackageApi(params) {
  return schttp({
    url: '/order-api/coupon_package/expand',
    method: 'POST',
    useBffApi: true,
    data: params,
  })
}

export const fetchAnimationDataApi = async CouponLottieUrl => {
  if (!CouponLottieUrl) return Promise.resolve()

  const controller = new AbortController()
  const { signal } = controller
  const timeoutId = setTimeout(() => {
    controller.abort()
  }, 3000)

  try {
    const res = await fetch(CouponLottieUrl, { signal })
    clearTimeout(timeoutId)
    if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`)
    return res.json()
  } catch (error) {
    clearTimeout(timeoutId)
    return null
  }
}
